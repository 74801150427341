<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row py-4">
        <div class="col-7">
          <h2 class="mb-0">Actualités</h2>
        </div>
      </div>
      <div class="row">
        <div class="card-columns">
          <news-card v-for="item in news" :key="item.id" :news="item"></news-card>
        </div>
        <infinite-loading @infinite="infiniteHandler" ref="infiniteLoading" class="col-lg-4 d-flex flex-column justify-content-center">
          <div slot="no-more"></div>
          <div slot="no-results">Il n'existe aucune actualité pour le moment.</div>
        </infinite-loading>
      </div>
    </base-header>
  </div>
</template>

<script>
import NewsCard from '@/components/AppComponent/Cards/NewsCard';
import BaseHeader from '@/components/BaseHeader';
import InfiniteLoading from 'vue-infinite-loading';
import { EventBus } from '@/plugins/event-bus';
import moment from 'moment';

export default {
  name: 'Holidays',
  components: {
    BaseHeader,
    NewsCard,
    InfiniteLoading,
  },
  data() {
    return {
      news: [],
      simpleSearch: {
        text: '',
        active: false,
      },
      paging: {
        currentPage: 0,
        perPage: 9,
      },
      showFilter: false,
    };
  },
  created() {
    // If the 'search' parameter is set it means that the user want to show the results of a search.
    if (this.$route.query.search) {
      this.search(this.$route.query.search);
    }
  },
  mounted() {
    EventBus.$on('search', searchField => {
      this.search(searchField);
    });
  },
  methods: {
    search(text) {
      this.news = [];
      this.paging.currentPage = 0;
      this.simpleSearch.text = text;

      if (text != '') {
        this.simpleSearch.active = true;
      } else {
        this.simpleSearch.active = false;
      }

      if (this.$refs.infiniteLoading != undefined) {
        this.$refs.infiniteLoading.stateChanger.reset();
      }
    },
    infiniteHandler($state) {
      let url;
      const params = {
        page: this.paging.currentPage,
        size: this.paging.perPage,
      };

      // When the user searched news with the search field.
      if (this.simpleSearch.active === true) {
        url = '/newsEntities/search/fulltext';
        params.title = this.simpleSearch.text;
        params.description = this.simpleSearch.text;
      // When the user filtered holidays by date.
      } else {
        url = `/newsEntities/search/publishedNews`;
      }

      return this.$http
        .get(process.env.VUE_APP_API_URL + url, { params })
        .then((response) => {
          if (response.status === 200) {
            if (this.paging.currentPage < response.data.page.totalPages) {
              this.paging.currentPage += 1;
              this.news = [...this.news, ...response.data._embedded.newsEntities];
              $state.loaded();
            } else {
              $state.complete();
            }
          }
        });
    },
    beforeDestroy() {
      EventBus.$off('search', this.searchField);
    }
  },
};
</script>

<style scoped>
  .card-columns {
    column-count: 2;
    width: 100%;
  }

  @media (max-width: 575px) {
    .card-columns {
      column-count: 1;
    }
  }
</style>
