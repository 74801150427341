<template>
  <div>
    <base-header class="pb-6" type="">
      <!-- Event Cards -->
      <div class="row card-wrapper py-3">
        <div class="col-lg-4 d-flex mx-auto">
          <div class="card" v-if="this.$route.params.item">
            <a @click="goBack()" class="btn btn-link btn-back pt-4"><i class="fa fa-arrow-left"></i></a>
            <h3 class="card-title mb-3 text-center pt-4 pr-6 pl-6">{{ this.$route.params.item.title }}
                <br><span class="text-sm text-muted text-center">Votre avis nous intéresse !</span>
            </h3>
            <div class="card-header logo-card-header text-center">
              <img v-if="$route.params.item.imgUrl" :src="getEventImage($route.params.item.imgUrl)" @error="altImage" alt="No Image" class="event-details-image">
            </div>
            <!-- Card body -->
            <div class="card-body">
              <div class="d-flex flex-row">
                <div class="d-flex flex-row text-center col-sm justify-content-center mb-3" ref="rateIcons">
                  <div v-for="star in maxStars" :key="star"
                      class="pr-2 pl-2 icon icon-lg icon-shape bg-white rounded-circle"
                      :class="{ 'active': star <= feedback.rating }">
                    <i :class=" star <= feedback.rating ? 'fa fa-star text-yellow emoji-icon' : 'fa fa-star text-muted emoji-icon'" @click="rate(star)"></i>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row mb-3">
                <textarea v-model="feedback.comment" class="form-control" rows="3" resize="none" placeholder="Un commentaire..."></textarea>
              </div>
              <div class="d-flex flex-column align-items-left text-justify ">
                <base-button icon type="primary" @click="send">Envoyer</base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Feedback',
  props: {
    maxStars: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      feedback: {
        userEmail: this.$store.getters['account/email'],
        manifestationId: this.$route.params.id,
        comment: '',
        rating: 0,
      },
    };
  },
  created() {
    // Redirect the user in the event's details card when he tries to access the feedback page without a context (e.g. by refreshing the page or by manually setting the url).
    if (!this.$route.params.item) {
      this.$router.push({ name: 'EventDetails', params: { id: this.$route.params.id } });
    } else {
      this.getManifestationUserRatingIfExists();
      this.getManifestationUserCommentIfExists();
    }
  },
  methods: {
    altImage(event) {
      event.target.src = "/img/ciip/ciip-logo.png";
    },
    rate(star) {
      if (star <= this.maxStars && star >= 0) {
        this.feedback.rating = this.feedback.rating === star ? star - 1 : star;
      }
    },
    getManifestationUserRatingIfExists() {
      return this.$http
        .get(`${process.env.VUE_APP_API_URL}/ratings?email=${this.$store.getters['account/email']}&manifestationId=${this.$route.params.id}`)
        .then((response) => {
          this.rate(response.data.rating);
        });
    },
    getManifestationUserCommentIfExists() {
      return this.$http
        .get(`${process.env.VUE_APP_API_URL}/comments?email=${this.$store.getters['account/email']}&manifestationId=${this.$route.params.id}`)
        .then((response) => {
          if (response.data.comment) {
            /* eslint no-underscore-dangle: ["error", { "allow": ["_embedded"] }] */
            this.feedback.comment = response.data.comment;
          }
        })
        .catch((error) => {
          if (error.response && error.response.status !== 404) {
            this.errorMessage = error.response.data.message;
          }
        });
    },
    send() {
      return this.$http
        .post(`${process.env.VUE_APP_API_URL}/commands/annotation`, {
          email: this.feedback.userEmail,
          manifestationId: this.feedback.manifestationId,
          comment: this.feedback.comment,
          rating: this.feedback.rating,
        }).then((response) => {
          if (response.status === 201) {
            this.$notify({
              message: 'Votre avis a bien été reçu. Merci !',
              timeout: 3000,
              icon: 'ni ni-bell-55',
              type: 'success',
            });
            this.$router.go(-1);
          } else {
            this.$notify({
              message: 'Un problème est survenu. Veuillez réessayer !',
              timeout: 3000,
              icon: 'ni ni-bell-55',
              type: 'danger',
            });
          }
        });
    },
    getEventImage(imageName) {
      return `${process.env.VUE_APP_API_URL}/files/img/${imageName}`;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
  .card {
    width: 100%;
  }
</style>