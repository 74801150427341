<template>
  <div>
    <calendar-event-prompt 
      :showCalendarEvent.sync="showCalendarEvent" 
      :event="clickedEvent"
      @close.stop="showCalendarEvent = false">
    </calendar-event-prompt>

    <base-header class="pb-6 content__title content__title--calendar bg-light-orange">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-white d-inline-block mb-0 title-calendar">Calendrier</h6>
          <base-checkbox v-model="showHolidays" class="show-holidays-checkbox">
            Afficher les vacances
          </base-checkbox>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6 calendar-container">
      <card body-classes="calendar-card-container">
        <FullCalendar
          ref="fullCalendar"
          defaultView="dayGridMonth"
          locale="fr"
          :header="{
            left: 'prev,next today',
            center: 'dayGridMonth,timeGridWeek,timeGridDay',
            right: 'title',
          }"
          :buttonText="{
            today: `Aujourd'hui`,
            month: 'Mois',
            week: 'Semaine',
            day: 'Jour',
          }"
          :plugins="calendarPlugins"
          :weekends="calendarWeekends"
          :events="calendarEvents"
          @eventClick="handleEventClick" />
      </card>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import CalendarEventPrompt from '@/components/AppComponent/Prompt/CalendarEventPrompt';
import Hammer from 'hammerjs';

const manifestationType = 'manifestation';
const holidayType = 'holiday';

export default {
  components: {
    FullCalendar,
    CalendarEventPrompt,
  },
  data() {
    return {
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
      ],
      calendarWeekends: true,
      calendarEvents: [],
      showCalendarEvent: false,
      clickedEvent: {},
      showHolidays: false,
    }
  },
  watch: {
    showHolidays(val) {
      if (val) {
        this.fetchAndAddHolidays();
      } else {
        this.calendarEvents = this.calendarEvents.filter(event => event.type !== holidayType);
      }
    }
  },
  created() {
    // Only fetch events because holidays are not visible by default (this choice was made to avoid overloading the UI).
    this.fetchAndAddEvents();
  },
  mounted() {
    // Add listeners to react when the user swipes on left and on right.
    var hammertime = new Hammer(this.$refs.fullCalendar.$el);

    hammertime.on('swipeleft', () => {
      this.$refs.fullCalendar.getApi().next();
    });

    hammertime.on('swiperight', () => {
      this.$refs.fullCalendar.getApi().prev();
    });
  },
  methods: {
    fetchAndAddEvents() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/manifestationEntities/search/findAllOrderByStartTimeDesc?projection=manifestationForCalendarDTO`)
        .then((response) => {
          this.calendarEvents = this.calendarEvents.concat(
            response.data._embedded.manifestationEntities.map(manifestation => ({
              type: manifestationType,
              id: manifestation.id,
              title: manifestation.title,
              start: manifestation.startTime,
              end: manifestation.endTime,
              color: '#f25244',
            }))
          );
        });
    },
    fetchAndAddHolidays() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}/holidayEntities/search/findAllOrderByStartTimeDesc?projection=holidayForCalendarDTO`)
        .then((response) => {
          this.calendarEvents = this.calendarEvents.concat(
            response.data._embedded.holidayEntities.map(holiday => ({
              type: holidayType,
              id: holiday.id,
              title: holiday.title,
              start: holiday.startTime,
              end: holiday.endTime,
              color: '#8c79b1',
            }))
          );
        });
    },
    handleEventClick(info) {
      let url = '/';

      if (info.event.extendedProps.type === manifestationType) {
        url += `manifestationEntities/${info.event.id}?projection=manifestationDTO`;
      } else if (info.event.extendedProps.type === holidayType) {
        url += `holidayEntities/${info.event.id}?projection=holidayDTO`;
      } else {
        throw('unknown event');
      }

      this.$http
        .get(process.env.VUE_APP_API_URL + url)
        .then((response) => {
          this.clickedEvent = {
            type: info.event.extendedProps.type,
            ...response.data
          };
          this.showCalendarEvent = true;
        });
    }
  }
}
</script>

<style lang='scss'>
// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
</style>

<style scoped>
  .show-holidays-checkbox {
    font-weight: 600;
  }
</style>