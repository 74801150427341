/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";

import "./assets/css/weather-icons/weather-icons.min.css";

// router setup
import router from "./routes/router";
import store from "./stores/store";

// Temporary code added to clear all the service workers of the users for this
// application, in order for them to use the new service worker process from now.
navigator.serviceWorker.getRegistrations().then((registrations) => {
  console.log('-> Clearing service workers...');

  for (const registration of registrations) {
    console.log(`-> Unregistering service worker...`, registration);
    registration.unregister();
  }

  console.log('-> Done.');
});

// Only load OneSignal if we are in production.
if (process.env.NODE_ENV === "production") {
  const initConfig = {
    appId: process.env.VUE_APP_ONESIGNAL,
    notifyButton: {
      enable: true
    }
  };

  window.OneSignal = window.OneSignal || [];

  // Since we already have a service worker and since we are supporting PWA in this application, we have
  // to customize the OneSignal's service worker integration by putting it in another scope (here, in the
  // "/push/onesignal" folder).
  // You can find more information here:
  // https://documentation.onesignal.com/docs/onesignal-service-worker-faq#customizing-your-service-worker-integration
  window.OneSignal.push(() => {
    window.OneSignal.SERVICE_WORKER_PARAM = { scope: "/push/onesignal/" };
    window.OneSignal.SERVICE_WORKER_PATH =
      "push/onesignal/OneSignalSDKWorker.js";
    window.OneSignal.SERVICE_WORKER_UPDATER_PATH =
      "push/onesignal/OneSignalSDKUpdaterWorker.js";
    window.OneSignal.init(initConfig);
  });
}

// Axios http requests and set as $http provider globally
import axios from "axios";

import "./registerServiceWorker";
const instance = axios.create();
instance.interceptors.request.use(function(config) {
  if (store.getters["account/token"]) {
    config.headers.Authorization = `Bearer ${store.getters["account/token"]}`;
  }

  return config;
});
Vue.prototype.$http = instance;

// plugin setup
Vue.use(DashboardPlugin);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  beforeCreate() {
    this.$store.dispatch("initialiseStore");
  },
  render: h => h(App),
  router
});
