<template>
  <a @click="goToHoliday(item.id)" class="card d-flex flex-row holiday-card rounded">
    <div class="flex-grow-0 flex-shrink-0 d-flex flex-column justify-content-center top-text px-3 pb-3 holiday-squared-div holiday-date rounded-left">
      <span class="text-center font-weight-light holiday-date-day">{{ getDateDay(item.startTime) }}</span>
      <span class="text-center holiday-date-month">{{ getDateMonth(item.startTime) }}</span>
      <span class="text-center holiday-date-year">{{ getDateYear(item.startTime) }}</span>
    </div>
    <div class="d-flex flex-column px-3 py-2 holiday-content justify-content-center">
      <h4 class="mb-0 holiday-title">{{ item.title }}</h4>
      <div class="d-flex align-items-center mt-2">
        <div class="event-icon-container">
          <div class="icon-shape icon-holiday">
            <i class="ni ni-pin-3"></i>
          </div>
        </div>
        <div class="event-address">
          <span class="text-sm" v-if="item.canton">{{ item.canton.name }}</span>
          <span class="text-sm" v-else>-</span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="event-icon-container">
          <div class="icon-shape icon-holiday">
            <i class="ni ni-calendar-grid-58"></i>
          </div>
        </div>
        <div>
          <span class="text-sm">{{ getHolidayStatus(item.startTime, item.endTime) }}</span>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      monthsAbr: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    };
  },
  props: {
    item: Object,
  },
  methods: {
    goToHoliday(index) {
      this.$router.push({ name: 'HolidayDetails', params: { id: index } });
    },
    getHolidayStatus(start, end) {
      const from = moment(start, 'YYYY-MM-DDThh:mm:ssZ');
      const to = moment(end, 'YYYY-MM-DDThh:mm:ssZ');
      const now = moment();

      if (now.isAfter(from) && now.isBefore(to)) {
        return 'Aujourd\'hui';
      } else if (from.diff(now.add(1, 'days'), 'days') === 0) {
        return 'Demain';
      } else if (now.isAfter(to)) {
        return 'Terminé';
      } else if (now.isBefore(from)) {
        return `Débute dans ${from.diff(now, 'days')} jours`;
      }

      return null;
    },
    getDateDay(date) {
      const eventDate = new Date(date);
      return eventDate.getUTCDate();
    },
    getDateMonth(date) {
      const eventDate = new Date(date);
      return this.monthsAbr[eventDate.getUTCMonth()];
    },
    getDateYear(date) {
      const eventDate = new Date(date);
      return eventDate.getUTCFullYear();
    },
    convertMS(milliseconds) {
      const seconds = Math.floor(milliseconds / 1000);
      const minute = Math.floor(seconds / 60);
      const hour = Math.floor(minute / 60);
      const day = Math.floor(hour / 24);
      return day;
    },
  },
};
</script>

<style lang="css" scoped>
  .top-icon {
    width: auto;
    height: auto;
    background-color: transparent;
  }

  .top-text {
    line-height: 1;
  }

  .card {
    box-shadow: 2px 4px 25px rgba(0, 0, 0, .1) !important;
    width: 100%;
  }

  .card:hover {
    box-shadow: 2px 8px 45px rgba(0, 0, 0, .15) !important;
  }
</style>