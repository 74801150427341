<template>
  <modal :show.sync="show"
          @click="closeFilterPrompt"
          body-classes="p-0">
    <card type="secondary"
          header-classes="pb-5"
          body-classes="px-lg-0 py-0 calendar-event-card-body"
          class="border-0 mb-0">
      <a @click="closeFilterPrompt()" class="btn btn-link btn-back pt-3"><i class="fa fa-arrow-left"></i></a>
      <h3 class="card-title mb-3 text-center pr-6 pl-6 event-title">{{ event.title }}</h3>
      <div v-if="event.type === manifestationType" class="event-inner-image" :style="`background: url('${getItemImage(event.image)}')`"></div>
      <div v-else class="card-header logo-card-header text-center">
        <img v-if="event.image" :src="getItemImage(event.image)" @error="altImage" alt="No Image" class="event-details-image">
      </div>
      <div class="card-body">
        <div class="d-flex flex-row mb-3 mx-0">
          <div class="d-flex flex-column text-center col-sm mr-2">
            <span class="text-sm font-weight-bold">{{ getDate(event.startTime) }}</span>
            <span class="text-sm">{{ getTime(event.startTime) }}</span>
          </div>
          <div class="text-sm">au</div>
          <div class="d-flex flex-column text-center col-sm ml-2">
            <span class="text-sm font-weight-bold">{{ getDate(event.endTime) }}</span>
            <span class="text-sm">{{ getTime(event.endTime) }}</span>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="d-flex flex-column align-items-left text-left">
              <div class="text-sm font-weight-bold">
                Description
              </div>
              <div>
                <span class="text-sm" v-html="event.description"></span>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="event.type === manifestationType">
            <div class="d-flex flex-column align-items-left text-justify">
              <div class="text-sm font-weight-bold">
                Lieu
              </div>
              <div>
                <span class="text-sm" v-if="event.address">
                  {{ event.address.street }} {{ event.address.number }}, {{ event.address.npa}} {{ event.address.city }}
                  <span v-if="event.address.country || event.address.canton">
                    (<span v-if="event.address.canton">{{ event.address.canton.code }}</span>
                    <span v-if="event.address.country && event.address.country.name !== 'Suisse' && event.address.canton">, </span>
                    <span v-if="event.address.country && event.address.country.name !== 'Suisse'">{{ event.address.country.name }}</span>)
                  </span>
                </span>
                <span class="text-sm" v-if="!event.address">-</span>
              </div>
            </div>
          </li>
          <li class="list-group-item" v-if="event.type === holidayType">
            <div class="d-flex flex-column align-items-left text-justify">
              <div class="text-sm font-weight-bold">
                Canton
              </div>
              <div>
                <span class="text-sm" v-if="event.canton">{{ event.canton.name }}</span>
                <span class="text-sm" v-else>-</span>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex flex-column align-items-left text-justify">
              <div title="Ajouter à mon calendrier" class="addeventatc add-to-calendar-btn">
                  Ajouter à mon calendrier
                  <span class="start">{{ event.startTime }}</span>
                  <span class="end">{{ event.endTime }}</span>
                  <span class="timezone">Europe/Zurich</span>
                  <span class="title">{{ event.title }}</span>
                  <span class="description">{{ event.description }}</span>
                  <span v-if="event.address" class="location">{{ event.address.street }} {{ event.address.number }}, {{ event.address.npa}} {{ event.address.city }}</span>
              </div>
              <base-button class="btn-event-opinion my-3" icon type="primary" @click="goToEvent()">Plus de détails</base-button>
            </div>
          </li>
        </ul>
      </div>
    </card>
  </modal>
</template>

<script>
import { Modal } from '@/components';
import { Select, Option } from 'element-ui';

export default {
  components: {
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['showCalendarEvent', 'event'],
  data() {
    return {
      months: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
      manifestationType: 'manifestation',
      holidayType: 'holiday',
    }
  },
  mounted() {
    // Load the AddEvent script in order to make the calendar button works.
    // It has to be loaded directly in the component in order to work, otherwise the script
    // won't be loaded correctly.
    let addEventScript = document.createElement('script');
    addEventScript.setAttribute('src', 'https://addevent.com/libs/atc/1.6.1/atc.min.js');
    document.head.appendChild(addEventScript);
  },
  computed: {
    show: {
      get() { return this.showCalendarEvent},
      set(val) { 
        this.$emit('update:showCalendarEvent', val);
      }
    },
  },
  methods: {
    closeFilterPrompt() {
      this.$emit('update:showCalendarEvent', false);
    },
    getItemImage(imageName) {
      return `${process.env.VUE_APP_API_URL}/files/img/${imageName}`;
    },
    altImage(event) {
      event.target.src = "/img/ciip/ciip-logo.png";
    },
    getDate(date) {
      const eventDate = new Date(date);
      const day = eventDate.getUTCDate();
      const month = eventDate.getUTCMonth();
      return `${day} ${this.months[month]} ${eventDate.getUTCFullYear()}`;
    },
    getTime(date) {
      const eventDate = new Date(date);
      const hours = eventDate.getUTCHours();
      const minutes = eventDate.getUTCMinutes();
      const myHours = hours < 10 ? `0${hours}` : hours;
      const myMintues = minutes < 10 ? `0${minutes}` : minutes;
      return `${myHours}:${myMintues}`;
    },
    goToEvent() {
      this.closeFilterPrompt();

      // Wait for the DOM to properly update (which means we are waiting for the modal to close) before redirecting the user to the
      // event's page. We have to process this way in order to prevent the scrollbar to disapear after the click.
      this.$nextTick(function () {
        this.$router.push({
          name: this.event.type === this.manifestationType ? 'EventDetails' : 'HolidayDetails',
          params: {
            id: this.event.id,
          },
        });
      });
    }
  },
};
</script>

<style scoped>
  .event-title {
    padding-top: 1.17rem !important;
  }
  
  .event-inner-image {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 12.5em;
    width: 100%;
  }
</style>