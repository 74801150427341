<template>
  <modal  :show.sync="show"
          @click="closeFilterPrompt"
          size="sm"
          body-classes="p-0">
    <card type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0">
        <div class="text-muted text-center mt-2 mb-3">
          <h3>Filtres</h3>
        </div>
      <template>
        <form role="form">
          <div class="row mb-4">
            <div class="col-md-12">
              <label class="filter-date-label">Mois de début</label>
              <vue-monthly-picker
                dateFormat="MM-YYYY"
                :monthLabels="monthsLabels"
                v-model="fromMonth">
              </vue-monthly-picker>
              <label class="filter-date-label mt-2">Mois de fin</label>
              <vue-monthly-picker
                dateFormat="MM-YYYY"
                :monthLabels="monthsLabels"
                :min="fromMonth"
                v-model="toMonth">
              </vue-monthly-picker>
            </div>
          </div>
          <div class="row" v-if="parentName != 'MyEvents'">
            <div class="col-md-12">
              <base-radio v-model="radio"
                          name="futurEvents"
                          class="mb-3"
                          :disabled="fromMonth != null && toMonth != null">
                Événements à venir
              </base-radio>
            </div>
          </div>
          <div class="row" v-if="parentName != 'MyEvents'">
            <div class="col-md-12">
              <base-radio v-model="radio"
                          name="pastEvents"
                          class="mb-3"
                          :disabled="fromMonth != null && toMonth != null">
                Événements passés
              </base-radio>
            </div>
          </div>
          <div class="row" v-if="parentName != 'MyEvents'">
            <div class="col-md-12">
              <base-radio v-model="radio"
                          name="allEvents"
                          class="mb-3"
                          :disabled="fromMonth != null && toMonth != null">
                Tous les événements
              </base-radio>
            </div>
          </div>
          <div class="text-center filter-buttons-container">
            <base-button type="primary" class="my-4 filter-button" @click="filter">Filtrer</base-button>
            <base-button type="primary" class="my-4 filter-button" @click="resetFilter">Réinitialiser</base-button>
            <base-button type="warning" class="my-4 filter-button" @click="closeFilterPrompt">Annuler</base-button>
          </div>
        </form>
      </template>
    </card>
  </modal>
</template>

<script>
import { Modal } from '@/components';
import { Select, Option } from 'element-ui';
import VueMonthlyPicker from 'vue-monthly-picker';

export default {
  components: {
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    VueMonthlyPicker,
  },
  props: ['showFilter', 'parentName'],
  data() {
    return {
      monthsLabels: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      fromMonth: null,
      toMonth: null,
      radio: 'futurEvents',
    };
  },
  computed: {
    show: {
      get() { return this.showFilter},
      set(val) { 
        this.$emit('update:showFilter', val);
      }
    },
  },
  methods: {
    closeFilterPrompt: function () {
      this.$emit('update:showFilter', false);
    },
    filter() {
      if (this.fromMonth && this.toMonth) {
        if (this.fromMonth.isAfter(this.toMonth)) {
          const temp = this.fromMonth;
          this.fromMonth = this.toMonth;
          this.toMonth = temp;
        }

        this.$router.push({ query: {
          fromMonth: this.fromMonth.format('MM-YYYY'),
          toMonth: this.toMonth.format('MM-YYYY'),
        }});
      } else {
        this.$router.push({ query: { type: this.radio }});
      }

      this.closeFilterPrompt();
    },
    resetFilter() {
      this.fromMonth = null;
      this.toMonth = null;
      this.radio = 'futurEvents';

      this.filter();
    },
  },
};
</script>