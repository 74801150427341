const filters = {
  strict: process.env.NODE_ENV === 'development',
  namespaced: true,
  state: {
    searchStack: [],
    orderBy: 'date',
    orderDirection: 'desc',
  },
  mutations: {
    setOrderBy(state, orderBy) {
      const newState = state;
      newState.orderBy = orderBy;
    },
    setOrderDirection(state, orderDirection) {
      const newState = state;
      newState.orderDirection = orderDirection;
    },
    setSearchStack(state, newSearchStack) {
      const newState = state;
      newState.searchStack = newSearchStack;
    },
  },
  actions: {
    updateOrderByAndSortHoliday({ commit, state, dispatch }, data) {
      commit('setOrderBy', data);
      dispatch('holiday/orderHoliday', { order: state.orderBy, direction: state.orderDirection },
        { root: true });
    },
    updateOrderDirection(context, data) {
      context.commit('setOrderDirection', data);
    },
  },
  getters: {
    getOrderBy(state) {
      return state.orderBy;
    },
    getOrderDirection(state) {
      return state.orderDirection;
    },
  },
};

export default filters;
