<template>
  <div>
    <base-header class="pb-6" type="">
      <!-- Event Cards -->
      <div class="row card-wrapper py-3">
        <div class="col-lg-6 d-flex mx-auto">
          <div class="card">
            <a @click="goBack()" class="btn btn-link btn-back pt-3"
              ><i class="fa fa-arrow-left"></i
            ></a>
            <h3 class="card-title mb-3 text-center pr-6 pl-6 event-title">
              {{ event.title }}
            </h3>
            <div
              class="event-inner-image"
              :style="`background: url('${getItemImage(event.image)}')`"
            ></div>
            <!-- Card body -->
            <div class="card-body">
              <div class="d-flex flex-row mb-3 mx-0 mx-md-8">
                <div class="d-flex flex-column text-center col-sm mr-2">
                  <span class="text-sm font-weight-bold">{{
                    getDate(event.startTime)
                  }}</span>
                  <span class="text-sm">{{ getTime(event.startTime) }}</span>
                </div>
                <div class="text-sm">au</div>
                <div class="d-flex flex-column text-center col-sm ml-2">
                  <span class="text-sm font-weight-bold">{{
                    getDate(event.endTime)
                  }}</span>
                  <span class="text-sm">{{ getTime(event.endTime) }}</span>
                </div>
              </div>
              <div
                class="d-flex mb-3 flex-column align-items-center text-justify"
              >
                <div class="text-sm">
                  <span class="duration">{{ duration }}</span>
                </div>
              </div>
              <!-- List group -->
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  :class="isOldEvent"
                  v-if="isConnected()"
                >
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column text-center col-sm">
                      <span class="text-sm font-weight-bold mb-1"
                        >Je participe ?</span
                      >
                      <div class="align-middle">
                        <base-switch
                          class="mr-1"
                          v-model="switches.register"
                          type="success"
                          @input="registerUser(event.id)"
                        ></base-switch>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  class="list-group-item"
                  v-if="event.organizer || event.website"
                >
                  <div class="d-flex flex-column align-items-left text-left">
                    <div class="text-sm font-weight-bold">
                      Organisateur
                    </div>
                    <div>
                      <div class="text-sm">{{ event.organizer }}</div>
                      <div class="text-sm">
                        <a :href="event.website" target="_blank">{{
                          event.website
                        }}</a>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  class="list-group-item"
                  v-if="event.speakers && event.speakers.length"
                >
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Intervenants
                    </div>
                    <div
                      v-for="speaker in event.speakers"
                      v-bind:key="speaker.id"
                      class="text-sm"
                    >
                      {{ speaker.lastName }} {{ speaker.firstName }}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="d-flex flex-column align-items-left text-left">
                    <div class="text-sm font-weight-bold">
                      Description
                    </div>
                    <div>
                      <span
                        class="text-sm description text-justify"
                        v-if="event.description"
                        v-html="event.description"
                      ></span>
                      <span class="text-sm" v-else>-</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Lieu
                    </div>
                    <div>
                      <div class="text-sm" v-if="event.address">
                        <div
                          v-if="
                            event.address.street ||
                              event.address.number ||
                              event.address.npa ||
                              event.address.city
                          "
                        >
                          {{ event.address.street }} {{ event.address.number }},
                          {{ event.address.npa }} {{ event.address.city }}
                          <span
                            v-if="event.address.country || event.address.canton"
                          >
                            (<span v-if="event.address.canton">{{
                              event.address.canton.code
                            }}</span>
                            <span
                              v-if="
                                event.address.country &&
                                  event.address.country.name !== 'Suisse' &&
                                  event.address.canton
                              "
                              >,
                            </span>
                            <span
                              v-if="
                                event.address.country &&
                                  event.address.country.name !== 'Suisse'
                              "
                              >{{ event.address.country.name }}</span
                            >)
                          </span>
                        </div>
                        <div v-if="event.address.room">
                          Salle {{ event.address.room }}
                        </div>
                      </div>
                      <span class="text-sm" v-else>-</span>
                    </div>
                    <div v-if="event.address && event.address.street">
                      <div class="map-container">
                        <div id="map"></div>
                      </div>
                      <div
                        class="text-sm mt-2"
                        v-if="route.distance && route.duration"
                      >
                        Trajet en voiture : {{ route.distance }} en
                        {{ route.duration }}
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="event.financialContribution">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Contribution financière
                    </div>
                    <div>
                      <span class="text-sm"
                        >CHF {{ financialContribution }}</span
                      >
                    </div>
                  </div>
                </li>
                <li
                  class="list-group-item"
                  v-if="
                    event.targetAudienceMinAge && event.targetAudienceMaxAge
                  "
                >
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Public cible
                    </div>
                    <div>
                      <span
                        v-if="
                          event.targetAudienceMinAge ===
                            event.targetAudienceMaxAge
                        "
                        class="text-sm"
                        >{{ event.targetAudienceMinAge }} ans</span
                      >
                      <span v-else class="text-sm"
                        >{{ event.targetAudienceMinAge }} à
                        {{ event.targetAudienceMaxAge }} ans</span
                      >
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="event.material">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Matériel
                    </div>
                    <div>
                      <span class="text-sm">{{ event.material }}</span>
                    </div>
                  </div>
                </li>
                <li
                  class="list-group-item"
                  v-if="educationalGoals && educationalGoals.length"
                >
                  <div class="d-flex flex-column align-items-left">
                    <div class="text-sm font-weight-bold">
                      Objectifs pédagogiques
                    </div>
                    <div>
                      <ul class="pl-3 mt-1">
                        <li
                          v-for="educationalGoal in educationalGoals"
                          v-bind:key="educationalGoal.id"
                          class="text-sm"
                        >
                          <a
                            :href="educationalGoal.websiteLink"
                            target="_blank"
                            >{{ educationalGoal.code }}</a
                          >
                          - {{ educationalGoal.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  class="list-group-item"
                  v-if="event.address && event.address.weather"
                >
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Détails météo
                    </div>
                    <div>
                      <span
                        class="text-sm"
                        :class="getWeatherIcon(event.address.weather.content)"
                      ></span>
                      <span class="text-sm">{{
                        getWeather(event.address.weather.content)
                      }}</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="event.format">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Format
                    </div>
                    <div>
                      <span class="text-sm">{{ format }}</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <base-button
                      class="btn-event-opinion"
                      icon
                      type="primary"
                      @click="goToFeedBack()"
                      v-if="isConnected()"
                      >Votre avis nous intéresse</base-button
                    >
                    <div
                      title="Ajouter à mon calendrier"
                      class="addeventatc add-to-calendar-btn"
                    >
                      Ajouter à mon calendrier
                      <span class="start">{{ event.startTime }}</span>
                      <span class="end">{{ event.endTime }}</span>
                      <span class="timezone">Europe/Zurich</span>
                      <span class="title">{{ event.title }}</span>
                      <span class="description">{{ event.description }}</span>
                      <span v-if="event.address" class="location"
                        >{{ event.address.street }} {{ event.address.number }},
                        {{ event.address.npa }} {{ event.address.city }}</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
// We need axios without the service in order to make requests on the EPROCOM API.
import axios from "axios";
import moment from "moment";

export default {
  name: "EventDetails",
  data() {
    return {
      event: {
        address: {
          weather: {}
        }
      },
      educationalGoals: [],
      distance: 0,
      pollCoords: undefined,
      months: [
        "Janv",
        "Févr",
        "Mars",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Déc"
      ],
      switches: {
        register: false
      },
      isOldEvent: false,
      prevRoute: null,
      formats: new Map([
        ["THEMATIC_COFFEE", "Café thématique"],
        ["SEMINAR", "Colloque"],
        ["WORKSHOP", "Atelier"]
      ]),
      route: {
        distance: null,
        duration: null
      }
    };
  },
  created() {
    this.fetch();
  },
  mounted() {
    // Load the AddEvent script in order to make the calendar button works.
    // It has to be loaded directly in the component in order to work, otherwise the script
    // won't be loaded correctly.
    let addEventScript = document.createElement("script");
    addEventScript.setAttribute(
      "src",
      "https://addevent.com/libs/atc/1.6.1/atc.min.js"
    );
    document.head.appendChild(addEventScript);
  },
  computed: {
    duration() {
      const startTime = moment(this.event.startTime);
      const endTime = moment(this.event.endTime);
      const diffInDays = endTime.diff(startTime, "days");
      const endTimeWithoutDays = endTime.subtract(diffInDays, "days");
      const diffInHours = endTimeWithoutDays.diff(startTime, "hours");
      const diffInMinutes = endTimeWithoutDays
        .subtract(diffInHours, "hours")
        .diff(startTime, "minutes");

      const textDays = diffInDays
        ? `${diffInDays} jour${diffInDays > 1 ? "s" : ""}${
            diffInHours || diffInMinutes ? ", " : ""
          }`
        : "";
      const textHours = diffInHours
        ? `${diffInHours} heure${diffInHours > 1 ? "s" : ""}${
            diffInMinutes ? ", " : ""
          }`
        : "";
      const textMinutes = diffInMinutes
        ? `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""}`
        : "";

      if (textDays || textHours || textMinutes) {
        return `${textDays}${textHours}${textMinutes}`;
      } else {
        return "1 jour";
      }
    },
    financialContribution() {
      return (Math.round(this.event.financialContribution * 100) / 100).toFixed(
        2
      );
    },
    format() {
      return this.formats.get(this.event.format);
    }
  },
  updated() {
    this.eventStatus();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    eventStatus() {
      const eventDateEndTime = moment(this.event.endTime);
      const today = moment(Date.now());
      this.isOldEvent = eventDateEndTime < today ? "d-none" : "";
    },
    getItemImage(imageName) {
      if (imageName) {
        return `${process.env.VUE_APP_API_URL}/files/img/${imageName}`;
      }
    },
    altImage(event) {
      event.target.src = "/img/ciip/ciip-logo.png";
    },
    fetch() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/manifestations/${this.$route.params.id}`
        )
        .then(response => {
          if (response.status === 200) {
            this.event = { ...this.event, ...response.data };

            // Fetch the registration of the user and show the registration switch only if the event is not already finished.
            if (
              moment(response.data.endTime).diff(moment()) > 0 &&
              this.$store.getters["account/email"] != null
            ) {
              this.$http
                .get(
                  `${process.env.VUE_APP_API_URL}/registrations/byUserEmailAndManifestationId?email=${this.$store.getters["account/email"]}&manifestationId=${this.$route.params.id}`
                )
                .then(response => {
                  if (response.data) {
                    this.switches.register = true;
                  }
                })
                .catch(error => {
                  if (error.response && error.response.status !== 404) {
                    this.errorMessage = error.response.data.message;
                  }
                });
            }

            // If we want to display the event on a Google map, we have to have at least the street of the event.
            if (this.event.address && this.event.address.street) {
              const scriptId = "googleMapApiScript";

              // Load the Google Map API if it is not already loaded.
              if (!document.getElementById(scriptId)) {
                // We have to dynamically load the script since we want to inject the API key from the .env files.
                // There are one key for the DEV environment (which has no restriction so you have to be careful about not using it in PROD),
                // and one key for the PROD environment (which has restrictions and is only accessible from the agenda.ciip.ch domain).
                // You can change the keys parameters and restrictions from the Google Cloud Platform with the account contained in KeePassXC.
                const script = document.createElement("script");
                script.id = scriptId;
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}&callback=initMap`;
                script.defer = true;
                script.async = true;

                // Callback function that is executed once the API is loaded and available.
                window.initMap = () => {
                  this.loadMap();
                };

                document.head.appendChild(script);
              } else {
                // If the script was already loaded, just call the map loading function.
                this.loadMap();
              }
            }
          }
        })
        .catch(error => {
          this.$notify({
            message: "Cette donnée n'existe pas",
            timeout: 4000,
            icon: "ni ni-bell-55",
            type: "danger"
          });
          this.$router.push({ path: "/events" });
        });

      this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/educationalGoalEntities/search/byIdManifestation?idManifestation=${this.$route.params.id}`
        )
        .then(responseEg => {
          this.educationalGoals = [];

          responseEg.data._embedded.educationalGoalEntities.forEach(
            educationalGoal => {
              // Fetch the details of the current educational goal in order to be able to display its code and name.
              // We don't use the axios service here because we are talking to another API (EPROCOM API)
              axios
                .get(
                  `${process.env.VUE_APP_EPROCOM_BACKEND_API_URL}/learningObjectives/search/byId?id=${educationalGoal.idEducationalGoal}`
                )
                .then(payload => {
                  // Check that we only have one occurrence of each educational goal.
                  if (
                    this.educationalGoals.filter(
                      educationalGoal =>
                        educationalGoal.idEducationalGoal === payload.data.id
                    ).length === 0
                  ) {
                    const websiteLink = `https://plandetudes.ch/web/guest/${payload.data.code.replace(
                      " ",
                      "_"
                    )}/`;

                    this.educationalGoals.push({
                      ...educationalGoal,
                      code: payload.data.code,
                      name: payload.data.name,
                      websiteLink
                    });
                  }
                });
            }
          );
        });
    },
    loadMap() {
      // Check that the web browser is compatible with the geolocation feature (getting the user's current position).
      if ("geolocation" in navigator) {
        // Get the user's position.
        navigator.geolocation.getCurrentPosition(
          userPosition => {
            this.showRouteOnMap(userPosition);
          },
          error => {
            // If the user refused the position access prompt, just show a marker on the event instead of the route.
            this.showEventMarkerOnMap();
          },
          {
            timeout: 1000, // Add a timeout on the location request in case it if blocked by the OS (in which case the error callback won't be trigger without a timeout) => https://stackoverflow.com/questions/65572629/check-if-a-browser-has-location-settings-blocked-at-an-operating-system-level
            maximumAge: 1000,
            enableHighAccuracy: true
          }
        );
      } else {
        // If the browser cannot get the user's position, just show a marker on the map on the event address.
        this.showEventMarkerOnMap();
      }
    },
    showRouteOnMap(userPosition) {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      // Initialize the Google map.
      const map = new google.maps.Map(document.getElementById("map"), {});
      directionsRenderer.setMap(map);

      // Create a route on the map, starting from the user's position and ending in the event's position.
      directionsService.route(
        {
          origin: {
            lat: userPosition.coords.latitude,
            lng: userPosition.coords.longitude
          },
          destination: `${this.event.address.street} ${this.event.address.number}, ${this.event.address.npa} ${this.event.address.city}`,
          travelMode: "DRIVING"
        },
        (response, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(response);

            var routeData = response.routes[0];
            this.route = {
              distance: routeData.legs[0].distance.text,
              duration: routeData.legs[0].duration.text
            };
          } else {
            // If an error occurred, just show a marker on the event instead of the route.
            this.showEventMarkerOnMap();
          }
        }
      );
    },
    showEventMarkerOnMap() {
      const geocoder = new google.maps.Geocoder();

      // Get the coordinates of the address of the event and create a map and a marker on the event's position.
      geocoder.geocode(
        {
          address: `${this.event.address.street} ${this.event.address.number}, ${this.event.address.npa} ${this.event.address.city}`
        },
        (results, status) => {
          if (status === "OK") {
            const map = new google.maps.Map(document.getElementById("map"), {
              center: results[0].geometry.location,
              zoom: 15
            });

            const eventMarker = new google.maps.Marker({
              map,
              position: results[0].geometry.location
            });
          }
        }
      );
    },
    getDate(date) {
      const eventDate = new Date(date);
      const day = eventDate.getDate();
      const month = eventDate.getMonth();
      return `${day} ${this.months[month]} ${eventDate.getFullYear()}`;
    },
    getTime(date) {
      const eventDate = new Date(date);
      const hours = eventDate.getHours();
      const minutes = eventDate.getMinutes();
      const myHours = hours < 10 ? `0${hours}` : hours;
      const myMintues = minutes < 10 ? `0${minutes}` : minutes;
      return `${myHours}:${myMintues}`;
    },
    getWeatherIcon(eventWeather) {
      let icon = "";
      if (eventWeather != null) {
        const eventWeatherJson = JSON.parse(eventWeather);
        const prefix = "wi wi-owm-";
        const code = eventWeatherJson.weather[0].id;
        const date = new Date();

        // Convert a Unix timestamp to time
        const sunrise = new Date(eventWeatherJson.sys.sunrise * 1000);
        const sunset = new Date(eventWeatherJson.sys.sunset * 1000);

        /* Get suitable icon for weather */
        if (
          date.getHours() >= sunrise.getHours() &&
          date.getHours() < sunset.getHours()
        ) {
          icon = `${prefix}day-${code}`;
        } else if (
          date.getHours() >= sunset.getHours() ||
          date.getHours() < sunrise.getHours()
        ) {
          icon = `${prefix}night-${code}`;
        }
      }
      return icon;
    },
    getWeather(eventWeather) {
      if (eventWeather != null) {
        const eventWeatherJson = JSON.parse(eventWeather);
        return ` ${Math.round(eventWeatherJson.main.temp)}°C`;
      }
      return "Indisponible";
    },
    /**
     * unit can be :
     * - (default) 'M' for miles
     * - 'K' for kilometers
     * - 'N' for nautical miles
     */
    calculateDistance(lat1, lon1, lat2, lon2, unit) {
      if (lat1 === lat2 && lon1 === lon2) {
        return "~";
      }

      const radlat1 = Math.PI * (lat1 / 180);
      const radlat2 = Math.PI * (lat2 / 180);
      const theta = lon1 - lon2;
      const radtheta = Math.PI * (theta / 180);
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist *= 180 / Math.PI;
      dist *= 60 * 1.1515;
      if (unit === "K") {
        dist *= 1.609344;
      }
      if (unit === "N") {
        dist *= 0.8684;
      }
      this.distance = Math.round(dist);
      return dist;
    },
    registerUser(eventId) {
      const data = {
        userEmail: this.$store.getters["account/email"],
        manifestationId: eventId
      };

      if (this.switches.register) {
        return this.$http
          .post(`${process.env.VUE_APP_API_URL}/commands/registration/`, data)
          .then(response => {
            this.$notify({
              message: "Votre inscription est confirmée !",
              timeout: 8000,
              icon: "ni ni-bell-55",
              type: "danger"
            });
          })
          .catch(() => {
            this.$notify({
              message:
                "Un problème est survenu lors de votre inscription. Veuillez réessayer ou contacter un administrateur.",
              timeout: 8000,
              icon: "ni ni-bell-55",
              type: "danger"
            });
          });
      } else {
        return this.$http
          .delete(`${process.env.VUE_APP_API_URL}/commands/registration/`, {
            data
          })
          .then(response => {
            this.$notify({
              message: "Votre désinscription est confirmée !",
              timeout: 8000,
              icon: "ni ni-bell-55",
              type: "danger"
            });
          })
          .catch(() => {
            this.$notify({
              message:
                "Un problème est survenu lors de votre désinscription. Veuillez réessayer ou contacter un administrateur.",
              timeout: 8000,
              icon: "ni ni-bell-55",
              type: "danger"
            });
          });
      }
    },
    goToFeedBack() {
      this.$router.push({
        name: "Feedback",
        params: {
          item: {
            title: this.event.title,
            imgUrl: this.event.image
          }
        }
      });
    },
    goBack() {
      if (this.prevRoute && this.prevRoute.name === "Events") {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "Events" });
      }
    },
    isConnected() {
      if (this.$store.getters["account/token"] != null) {
        return true;
      }
      return false;
    }
  },
  beforeDestroy() {
    navigator.geolocation.clearWatch(this.pollCoords);
  }
};
</script>

<style scoped>
.card {
  width: 100%;
}

.duration {
  font-size: 0.8rem !important;
}

.description {
  line-height: 1.7;
}

.event-title {
  padding-top: 1.17rem !important;
}

.event-inner-image {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 20em;
  width: 100%;
}

.map-container {
  height: 350px;
  margin-top: 0.5em;
  width: 100%;
}

#map {
  height: 100%;
  width: 100%;
}

@media (max-width: 700px) {
  .event-inner-image {
    height: 12.5em;
  }
}
</style>

<style>
.description p:last-of-type {
  margin-bottom: 0;
}
</style>
