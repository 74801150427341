<template>
  <div>
    <base-header class="pb-6" type="">
      <install-prompt></install-prompt>
      <filter-prompt
        :parentName="$options.name" 
        :showFilter.sync="showFilter" 
        @close.stop="showFilter = false">
      </filter-prompt>
      <div class="row align-items-center py-4">
        <div class="d-flex">
          <h6 class="h2 d-inline-block mb-0 events-title">{{ title }}</h6>
          <base-button size="sm" type="neutral" class="ml-3" @click.stop="showFilter = !showFilter" v-if="!simpleSearch.active"><i class="fas fa-filter"></i></base-button>
        </div>
      </div>
      <!-- Event Cards -->
      <div class="row card-wrapper">
        <div class="col-lg-4 d-flex flex-column event" v-for="(item, index) in eventList" :key="index">
          <event-card :item="item"></event-card>
        </div>
        <infinite-loading @infinite="infiniteHandler" ref="infiniteLoading" class="col-lg-4 d-flex center justify-content-center">
          <div slot="no-more"></div>
          <div slot="no-results">Aucune manifestation à venir</div>
        </infinite-loading>
      </div>
    </base-header>
  </div>
</template>

<script>
// Components
import EventCard from '@/components/AppComponent/Cards/EventCard';
import BaseHeader from '@/components/BaseHeader';
import InfiniteLoading from 'vue-infinite-loading';
import InstallPrompt from '@/components/AppComponent/Prompt/InstallPrompt';
import FilterPrompt from '@/components/AppComponent/Prompt/FilterPrompt';
import { setTimeout } from 'timers';
import { EventBus } from '@/plugins/event-bus';
import moment from 'moment';

export default {
  name: 'MyEvents',
  data() {
    return {
      title: 'Mes manifestations',
      eventList: [],
      simpleSearch: {
        text: '',
        active: false,
      },
      filterData: {
        dates: '',
        type: '',
      },
      paging: {
        currentPage: 0,
        totalPages: 0,
        perPage: 9,
      },
      showFilter: false,
    };
  },
  components: {
    BaseHeader,
    EventCard,
    InfiniteLoading,
    InstallPrompt,
    FilterPrompt,
  },
  created() {
    // If the 'search' parameter is set it means that the user want to show the results of a search.
    if (this.$route.query.search) {
      this.search(this.$route.query.search);
    }
  },
  beforeUpdate() {
    if (this.simpleSearch.active && this.$refs.infiniteLoading != undefined) {
      this.$refs.infiniteLoading.stateChanger.reset();
    }
  },
  mounted() {
    EventBus.$on('search', searchField => {
      this.search(searchField);
    });
  },
  watch: {
    // Watch after the parameters of the query in order to start a filter process if the right parameters are set
    '$route.query': {
      handler(newVal, oldVal) {
          this.filter({
            dates: newVal.fromMonth && newVal.toMonth ? [
              `01-${newVal.fromMonth}`,
              `${moment(newVal.toMonth, "MM-YYYY").daysInMonth()}-${newVal.toMonth}`, // Get last date of the month
            ] : '',
          });
      },
      immediate: true,
    },
  },
  methods: {
    resetEvents() {
      this.eventList = [];
      this.paging.currentPage = 0;
      this.paging.totalPages = 1;
      this.filterData = {
        dates: '',
        type: '',
      };
    },
    search(text) {
      this.resetEvents();
      this.simpleSearch.text = text;
      if (text != '') {
        this.simpleSearch.active = true;
      } else {
        if (this.$refs.infiniteLoading != undefined) {
          this.simpleSearch.active = false;
          this.$refs.infiniteLoading.stateChanger.reset();
        }
      }
    },
    filter(data) {
      this.resetEvents();
      this.filterData = data;

      if (this.$refs.infiniteLoading != undefined) {
        this.$refs.infiniteLoading.stateChanger.reset();
      }
    },
    infiniteHandler($state) {
      const date = new Date();
      const dateStr = date.toUTCString();
      let url = '/usersManifestations';
      let params;

      // When the user searched events with the search field.
      if (this.simpleSearch.active === true) {
        this.title = `Toutes mes manifestations`;

        url += '/search';
        params = {
          email: this.$store.getters['account/email'],
          value: this.simpleSearch.text,
          page: this.paging.currentPage,
          size: this.paging.perPage,
        };
      // When the user filtered events by date.
      } else if (this.filterData.dates) {
        this.title = `Mes manifestations du ${this.filterData.dates[0]} au ${this.filterData.dates[1]}`;

        url += '/inBetweenDates';
        params = {
          email: this.$store.getters['account/email'],
          start: new Date(`${this.filterData.dates[0].substring(6, 10)}-${this.filterData.dates[0].substring(3, 5)}-${this.filterData.dates[0].substring(0, 2)}`).toUTCString(),
          end: new Date(`${this.filterData.dates[1].substring(6, 10)}-${this.filterData.dates[1].substring(3, 5)}-${this.filterData.dates[1].substring(0, 2)}`).toUTCString(),
          page: this.paging.currentPage,
          size: this.paging.perPage,
        };
      } else {
        this.title = `Toutes mes manifestations`;

        params = {
          email: this.$store.getters['account/email'],
          page: this.paging.currentPage,
          size: this.paging.perPage,
        };
      }

      return this.$http
        .get(process.env.VUE_APP_API_URL + url, { params }).then((response) => {
          if (response.status === 200) {
            this.paging.totalPages = response.data.totalPages;

            if (this.paging.currentPage < this.paging.totalPages) {
              this.paging.currentPage += 1;
              this.eventList = [...this.eventList, ...response.data.content];
              $state.loaded();
            } else {
              $state.complete();
            }
          }
        });
    },
    beforeDestroy() {
      EventBus.$off('search', this.searchField);
    }
  },
};
</script>

<style scoped>
  @media (max-width: 767px) {
    .events-title {
      margin-left: 15px;
    }
  }
</style>