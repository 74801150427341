<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item :link="{
                        name: 'Actualités',
                        icon: 'ni ni-email-83 text-mine',
                        path: '/news',
                      }">
        </sidebar-item>
        <sidebar-item :link="{
                        name: 'Manifestations',
                        icon: 'ni ni-bullet-list-67 text-mine',
                        path: '/events',
                      }">
        </sidebar-item>
        <sidebar-item :link="{
                        name: 'Vacances scolaires',
                        icon: 'fas fa-globe-europe text-mine',
                        path: '/holidays',
                      }">
        </sidebar-item>
        <sidebar-item class="mb-2" :link="{
                        name: 'Calendrier',
                        icon: 'ni ni-calendar-grid-58 text-mine',
                        path: '/calendar',
                      }">
        </sidebar-item>
        <sidebar-item class="pt-2 border-top" :link="{
                        name: 'Support',
                        icon: 'fa fa-life-ring text-mine',
                        path: '//support.ciip.ch/tickets-create?applications=AGENDA',
                        target: '_blank',
                        rel: 'noopener',
                      }">
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :showSearchBar="showSearchBar()" :type="$route.meta.navbarType"></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)" class="dashboard-content">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      FadeTransition,
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('scrollbar-inner');
        }
      },
      showSearchBar() {
        if (this.$route.name === "EventDetails" || this.$route.name === "HolidayDetails" || this.$route.name === "Feedback" || this.$route.name === "Profile") {
          return false;
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>
