<template>
  <modal :show.sync="modals.notice"
          modal-classes="modal-danger"
          modal-content-classes="bg-danger">

    <div class="py-3 text-center">
      <i class="ni ni-bell-55 ni-3x"></i>
      <h4 class="heading mt-4">Une nouvelle version est disponible !</h4>
      <p>Mettez à jour votre application !</p>
    </div>

    <template slot="footer">
      <base-button id="refresh-button" @click="refreshApp" type="white">Mettre à jour</base-button>
      <base-button type="link" class="text-white ml-auto" @click="modals.notice = false">Pas maintenant</base-button>
    </template>

  </modal>
</template>

<script>
import { Modal } from '@/components';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      modals: {
        notice: false,
      },
    };
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.modals.notice = true;
    },
    refreshApp() {
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage('skipWaiting');
      this.modals.notice = false;
    },
  },
  created() {
    // --- 
    // Custom code to let user update the app
    // when a new service worker is available
    // --- 
    document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
                                
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    })
  },
};
</script>