import Vue from 'vue';
import Vuex from 'vuex';

import holiday from './modules/holiday';
import filters from './modules/filters';
import account from './modules/account';
import settings from './modules/settings';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    holiday,
    filters,
    account,
    settings,
  },
  actions: {
    initialiseStore({ dispatch }) {
      dispatch("account/init");
      dispatch("settings/init");
    },
  },
});

export default store;
