<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Mon profil</h3>
      </div>
    </div>

    <h6 class="heading-small text-muted mb-4">Informations personnelles</h6>

    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-6">
          <base-input type="text" label="Genre">
            <el-select
              v-model="user.gender"
              placeholder="Sélectionner"
              filterable
            >
              <el-option
                v-for="gender in genders"
                :key="gender.label"
                :label="gender.label"
                :value="gender.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <base-input
            type="text"
            label="*Prénom"
            placeholder="Prénom"
            v-model="user.firstName"
            disabled
          >
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input
            type="text"
            label="*Nom"
            placeholder="Nom"
            v-model="user.lastName"
            disabled
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <base-input
            type="email"
            label="*Adresse e-mail"
            placeholder="exemple@gmail.com"
            v-model="user.email"
            disabled
          >
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input type="text" label="N° de téléphone" v-model="user.phone">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <base-input
            type="text"
            label="Lieu d'habitation"
            placeholder="ex : Neuchâtel"
            v-model="user.hometown"
          >
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input label="Date de naissance">
            <flat-picker
              slot-scope="{ focus, blur }"
              placeholder="Choisissez une date à l'aide du calendrier"
              @on-open="focus"
              @on-close="blur"
              :config="{ allowInput: true, maxDate: new Date() }"
              class="form-control datepicker"
              v-model="user.birthDate"
            >
            </flat-picker>
          </base-input>
        </div>
      </div>
    </div>

    <hr class="my-4" />
    <h6 class="heading-small text-muted mb-4">Informations professionnelles</h6>

    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-12">
          <base-input
            label="Titre professionel"
            placeholder="ex : Enseigant"
            v-model="user.jobTitle"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <base-input
            type="text"
            label="Institution"
            placeholder="Nom de l'institution"
            v-model="user.institutionName"
          >
          </base-input>
        </div>
        <div class="col-md-2">
          <base-input
            type="text"
            label="Acronyme"
            placeholder="ex : CIIP"
            v-model="user.institutionAcronym"
          >
          </base-input>
        </div>
      </div>
    </div>

    <hr class="my-4" />
    <h6 class="heading-small text-muted mb-4">À propos de moi</h6>

    <div class="pl-lg-4">
      <div class="form-group">
        <label class="form-control-label">Notes</label>
        <textarea
          rows="4"
          class="form-control"
          placeholder="En quelques mots..."
          resize="none"
          v-model="user.notes"
        ></textarea>
      </div>
    </div>

    <hr class="my-4" />
    <div class="row">
      <div class="col-md-12">
        <p>
          <small
            >* Ces champs proviennent de votre fournisseur d'identité externe
            (SWITCHaii, Google, ...) et sont à changer directement auprès de
            lui.</small
          >
        </p>
        <div
          class="text-sm text-red"
          v-for="errorMessage in errorsMessage"
          :key="errorMessage"
        >
          <p v-if="errorMessage" class="alert alert-danger errorMessageInModal">
            {{ errorMessage }}
          </p>
        </div>
        <button
          class="btn btn-primary"
          :disabled="isSaving"
          @click="submitForm"
        >
          Enregistrer
        </button>
        <button
          class="btn btn-danger"
          :disabled="isSaving"
          @click="promptDeleteProfile"
        >
          Supprimer mon profil
        </button>
      </div>
    </div>
  </card>
</template>
<script>
import Swal from "sweetalert2";
import { Select, Option } from "element-ui";
import FlatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "Profile",
  components: {
    FlatPicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      user: {},
      passwords: {},
      genders: [
        {
          label: "Homme",
          value: "MALE"
        },
        {
          label: "Femme",
          value: "FEMALE"
        }
      ],
      errorsMessage: [],
      isSaving: false
    };
  },
  created() {
    this.fetchUserInfo();
  },
  methods: {
    submitForm() {
      this.errorsMessage = [];

      if (!this.user.email) {
        this.errorsMessage.push("Veuillez saisir une adresse e-mail !");
      }

      if (!this.user.email.includes("@")) {
        this.errorsMessage.push("L'adresse e-mail entrée n'est pas valide !");
      }

      if (this.user.newPassword !== this.user.newPasswordConfirm) {
        this.errorsMessage.push(
          "Les nouveaux mots de passe entrés de correspondent pas !"
        );
      }

      if (!this.errorsMessage.length) {
        this.updateProfile();
      }
    },
    updateProfile() {
      this.isSaving = true;
      return this.$http
        .put(
          `${process.env.VUE_APP_API_URL}/commands/user/${this.user.id}`,
          this.user
        )
        .then(() => {
          this.isSaving = false;
          this.fetchUserInfo();
          this.$notify({
            message: "Les modifications ont été enregistrées avec succès.",
            timeout: 8000,
            icon: "ni ni-bell-55",
            type: "danger"
          });
        })
        .catch(error => {
          this.isSaving = false;
          this.errorsMessage.push(
            "Une erreur est survenue lors de la sauvegarde, veuillez contrôler vos champs et réessayer."
          );
        });
    },
    promptDeleteProfile() {
      Swal({
        title: "Êtes-vous sûr de vouloir supprimer votre profil Agenda CIIP ?",
        text: "Le compte de votre fournisseur d'identité externe (SWITCHaii, Google, etc.) sera conservé, mais nous supprimerons toutes les données lié à votre profil Agenda CIIP et stockées dans notre base de données (y compris les inscriptions aux manifestations ainsi que les avis rédigés).",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer toutes mes données',
        cancelButtonText: 'Non, annuler',
        confirmButtonClass: 'btn btn-danger',
        cancelButtonClass: 'btn btn-success',
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteProfile();
        }
      });
    },
    deleteProfile() {
      this.isSaving = true;

      return this.$http
        .delete(
          `${process.env.VUE_APP_API_URL}/commands/user/${this.user.id}`)
        .then(() => {
          this.isSaving = false;
          this.$store.dispatch('account/logout');
          location.reload(); 
        })
        .catch(() => {
          this.isSaving = false;
          this.errorsMessage.push(
            "Une erreur est survenue lors de la suppression, veuillez réessayer ou contacter le support."
          );
        });
    },
    fetchUserInfo() {
      return this.$http
        .get(
          `${process.env.VUE_APP_API_URL}/users/byEmail?email=${this.$store.getters["account/email"]}`
        )
        .then(response => {
          if (response.status === 200) {
            this.user = { ...this.user, ...response.data };
          }
        });
    }
  }
};
</script>

<style>
.el-input.is-disabled .el-input__inner {
  color: #8898aa;
}

.has-label {
  height: 46px;
}

.el-select {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.el-select .el-input .el-input__inner {
  height: 44px;
}
</style>
