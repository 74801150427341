var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('calendar-event-prompt',{attrs:{"showCalendarEvent":_vm.showCalendarEvent,"event":_vm.clickedEvent},on:{"update:showCalendarEvent":function($event){_vm.showCalendarEvent=$event},"update:show-calendar-event":function($event){_vm.showCalendarEvent=$event},"close":function($event){$event.stopPropagation();_vm.showCalendarEvent = false}}}),_c('base-header',{staticClass:"pb-6 content__title content__title--calendar bg-light-orange"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-12 col-12"},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0 title-calendar"},[_vm._v("Calendrier")]),_c('base-checkbox',{staticClass:"show-holidays-checkbox",model:{value:(_vm.showHolidays),callback:function ($$v) {_vm.showHolidays=$$v},expression:"showHolidays"}},[_vm._v(" Afficher les vacances ")])],1)])]),_c('div',{staticClass:"container-fluid mt--6 calendar-container"},[_c('card',{attrs:{"body-classes":"calendar-card-container"}},[_c('FullCalendar',{ref:"fullCalendar",attrs:{"defaultView":"dayGridMonth","locale":"fr","header":{
          left: 'prev,next today',
          center: 'dayGridMonth,timeGridWeek,timeGridDay',
          right: 'title',
        },"buttonText":{
          today: "Aujourd'hui",
          month: 'Mois',
          week: 'Semaine',
          day: 'Jour',
        },"plugins":_vm.calendarPlugins,"weekends":_vm.calendarWeekends,"events":_vm.calendarEvents},on:{"eventClick":_vm.handleEventClick}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }