<template>
  <div>
    <update-SW-prompt></update-SW-prompt>
    <router-view></router-view>
  </div>
</template>

<script>
import UpdateSWPrompt from '@/components/AppComponent/Prompt/UpdateSWPrompt';
export default {
  components: {
    UpdateSWPrompt,
  }
}
</script>

<style>
  @media (min-width: 1191px) {
    .sidenav {
      width: 250px !important;
    }

    .navbar.navbar-top {
      width: calc(100% - 250px);
    }
  }
</style>