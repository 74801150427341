<template>
  <div>
    <base-header class="pb-6" type="">
      <!-- Event Cards -->
      <div class="row card-wrapper py-4">
        <div class="col-lg-6 d-flex mx-auto">
          <div class="card">
            <a @click="goBack()" class="btn btn-link btn-back pt-4"><i class="fa fa-arrow-left"></i></a>
            <h3 class="card-title mb-3 text-center pt-4 pr-6 pl-6">{{ holiday.title }}</h3>
            <div class="card-header logo-card-header text-center">
              <img v-if="holiday.image" :src="getItemImage(holiday.image)" @error="altImage" class="holiday-details-image">
            </div>
            <!-- Card body -->
            <div class="card-body">
              <div class="d-flex flex-row mb-3 mx-0 mx-md-8">
                <div class="d-flex flex-column text-center col-sm mr-2">
                  <span class="text-sm font-weight-bold">{{ getDate(holiday.startTime) }}</span>
                  <span class="text-sm">{{ getTime(holiday.startTime) }}</span>
                </div>
                <div class="text-sm">au</div>
                <div class="d-flex flex-column text-center col-sm ml-2">
                  <span class="text-sm font-weight-bold">{{ getDate(holiday.endTime) }}</span>
                  <span class="text-sm">{{ getTime(holiday.endTime) }}</span>
                </div>
              </div>
              <!-- List group -->
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="d-flex flex-column align-items-left text-left">
                    <div class="text-sm font-weight-bold">
                      Description
                    </div>
                    <div>
                      <span class="text-sm description" v-if="holiday.description" v-html="holiday.description"></span>
                      <span class="text-sm" v-else>-</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Canton
                    </div>
                    <div>
                      <span class="text-sm" v-if="holiday.canton">{{ holiday.canton.name }}</span>
                      <span class="text-sm" v-else>-</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item" v-if="holiday.level">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div class="text-sm font-weight-bold">
                      Degré
                    </div>
                    <div>
                      <span class="text-sm">{{ level }}</span>
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div class="d-flex flex-column align-items-left text-justify">
                    <div title="Ajouter à mon calendrier" class="addeventatc add-to-calendar-btn">
                        Ajouter à mon calendrier
                        <span class="start">{{ holiday.startTime }}</span>
                        <span class="end">{{ holiday.endTime }}</span>
                        <span class="timezone">Europe/Zurich</span>
                        <span class="title">{{ holiday.title }}</span>
                        <span class="description">{{ holiday.description }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HolidayDetails',
  data() {
    return {
      backLabelText: '',
      holiday: {},
      months: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
      levels: new Map([
        ['PRE_PRIMARY', 'Préprimaire'],
        ['PRIMARY', 'Primaire'],
        ['SECONDARY_1', 'Secondaire I'],
        ['SECONDARY_2', 'Secondaire II'],
      ]),
    };
  },
  created() {
    if (this.$store.getters['holiday/getHoliday'](this.$route.params.id) === undefined) {
      this.fetchHoliday();
    } else {
      this.holiday = {...this.holiday, ...this.$store.getters['holiday/getHoliday'](this.$route.params.id)};
    }
  },
  mounted() {
    // Load the AddEvent script in order to make the calendar button works.
    // It has to be loaded directly in the component in order to work, otherwise the script
    // won't be loaded correctly.
    let addEventScript = document.createElement('script');
    addEventScript.setAttribute('src', 'https://addevent.com/libs/atc/1.6.1/atc.min.js');
    document.head.appendChild(addEventScript);
  },
  computed: {
    level() {
      return this.levels.get(this.holiday.level);
    },
  },
  methods: {
    getItemImage(imageName) {
      return `${process.env.VUE_APP_API_URL}/files/img/${imageName}`;
    },
    altImage(event) {
      event.target.src = "/img/ciip/ciip-logo.png";
    },
    getDate(date) {
      const holidayDate = new Date(date);
      const day = holidayDate.getUTCDate();
      const month = holidayDate.getUTCMonth();
      return `${day} ${this.months[month]} ${holidayDate.getUTCFullYear()}`;
    },
    getTime(date) {
      const holidayDate = new Date(date);
      const hours = holidayDate.getUTCHours();
      const minutes = holidayDate.getUTCMinutes();
      const myHours = hours < 10 ? `0${hours}` : hours;
      const myMintues = minutes < 10 ? `0${minutes}` : minutes;
      return `${myHours}:${myMintues}`;
    },
    fetchHoliday() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/holidayEntities/${this.$route.params.id}`)
      .then((response) => {
        if (response.status === 200) {
          this.holiday = {...this.holiday, ...response.data};
        }
      }).catch(error => {
        this.$notify({
            message: 'Cette donnée n\'existe pas',
            timeout: 4000,
            icon: 'ni ni-bell-55',
            type: 'danger',
          });
        this.$router.push({ path: '/holidays'});
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
  .card {
    width: 100%;
  }

  .description {
    line-height: 1.7;
  }
</style>