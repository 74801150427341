const holiday = {
  strict: process.env.NODE_ENV === 'development',
  namespaced: true,
  state: {
    holidayStack: [],
  },
  mutations: {
    setHoliday(state, newHoliday) {
      const newState = state;
      newState.holidayStack = newHoliday;
    },
  },
  actions: {
    addHoliday({ commit, state }, data) {
      const localHoliday = state.holidayStack;
      for (let i = 0; i < data.length; i += 1) {
        localHoliday.push(data[i]);
      }
      commit('setHoliday', localHoliday);
    },
    orderHoliday({ commit, state }, data) {
      const localHoliday = state.holidayStack;
      /*
        Switch what we are ordering by.
      */
      switch (data.order) {
        case 'title':
          localHoliday.sort((a, b) => {
            if (data.direction === 'desc') {
              if (a.title === b.title) {
                return 0;
              } else if (a.title < b.title) {
                return 1;
              }
              return -1;
            }

            if (a.title === b.title) {
              return 0;
            } else if (a.title > b.title) {
              return 1;
            }
            return -1;
          });
          break;
        case 'date':
          localHoliday.sort((varA, varB) => {
            const a = new Date(varA.startTime).getTime();
            const b = new Date(varB.startTime).getTime();
            if (data.direction === 'desc') {
              if (a === b) {
                return 0;
              } else if (a < b) {
                return 1;
              }
              return -1;
            }

            if (a === b) {
              return 0;
            } else if (a > b) {
              return 1;
            }
            return -1;
          });
          break;
        default:
          break;
      }
      commit('setHoliday', localHoliday);
    },
  },
  getters: {
    getHoliday: state => id => state.holidayStack.filter(h => h.id === id)[0],
  },
};

export default holiday;
