<template>
  <div>
    <base-header class="pb-6" type="">
      <notifications></notifications>
      <div class="row card-wrapper py-4">
        <div class="col-xl-8 order-xl-1 mx-auto">
          <profile-form></profile-form>
        </div>
      </div>
    </base-header>
  </div>
</template>

<script>
  import ProfileForm from './ProfileForm';

  export default {
    components: {
      ProfileForm,
    }
  };
</script>