<template>
  <div class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
       @mouseenter="$sidebar.onMouseEnter()"
       @mouseleave="$sidebar.onMouseLeave()"
       :data="backgroundColor">
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" href="#">
          Agenda CIIP
        </a>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidebar-close-button" @click="closeSidebar">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </ul>
        <slot name="links-after"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: '/img/ciip/ciip_big_logo.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  created() {
    window.addEventListener("resize", this.showSidebarWhenWindowResized);
  },
  mounted() {
    if (this.$sidebar) {
      this.showFixedSidebar();
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.showSidebarWhenWindowResized);
  },
  methods: {
    showFixedSidebar() {
      // Show and fix the sidebar in desktop mode, then change the "showSidebar" mobile
      // variable in order to avoid bugs when switching from one mode to another.
      this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
      this.$sidebar.toggleMinimize();
      this.$sidebar.showSidebar = !this.$sidebar.isMinimized;
    },
    showSidebarWhenWindowResized(e) {
      // If we were on mobile mode with the sidebar closed and we are switching in desktop mode,
      // the sidebar need to be shown again, since it is always shown in desktop mode.
      if (e.target.innerWidth >= 1191 && this.$sidebar && !this.$sidebar.showSidebar) {
        this.showFixedSidebar();
      }
    },
    closeSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
  }
};
</script>

<style scoped>
  .sidebar-close-button {
    cursor: pointer;
    padding: 1.5rem;
  }

  @media (min-width: 1191px) {
    .sidebar-close-button {
      display: none;
    }
  }
</style>