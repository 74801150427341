<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand sticky"
    :class="{'bg-mine navbar-dark': type === 'default'}"
  >
    <ul class="navbar-nav align-items-center sidenav-toggler-container">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>
    <!-- Search form -->
    <form v-if="showSearchBar" class="navbar-search form-inline mr-sm-3"
          :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}"
          id="navbar-search-main"
          @submit.prevent="search()">
      <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text" @click.prevent="search()"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Recherche" type="text" v-model="searchField">
          <div class="input-group-append">
            <div class="input-group-text cursor-displayed"
              @click.prevent="clearField">
              <i class="fas fa-times"></i>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="close" :data-action="'search-close'" :data-target="'#navbar-search-main'" aria-label="Close">
        <span aria-hidden="false">×</span>
      </button>
    </form>
    <!-- Navbar links -->
    <div v-if="showSearchBar" class="navbar-nav d-sm-none">
      <a class="nav-link" @click="showMiniSearch = true">
        <i class="fas fa-search"></i>
      </a>
      <search-prompt :show.sync="showMiniSearch"
              size="sm"
              modal-classes="mini-search"
              body-classes="p-0">
          <form class="form-inline mr-sm-3"
              :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}"
              @submit.prevent="search()">
          <div class="form-group mb-0 mini-search-group-form-group">
            <div class="input-group input-group-alternative input-group-merge">
              <div class="input-group-prepend">
                <span class="input-group-text" @click.prevent="search()"><i class="fas fa-search"></i></span>
              </div>
              <input class="form-control" placeholder="Recherche" type="text" v-model="searchField" ref="minisearchinputref" />
              <div class="input-group-append">
                <div class="input-group-text cursor-displayed"
                  @click.prevent="clearField">
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
        </form>
      </search-prompt>
    </div>
    <ul v-if="isConnected()" class="navbar-nav align-items-center ml-auto ml-md-0 user-initials-container">
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                {{ userInitials }}
              </span>
          </div>
        </a>

        <template>

          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bonjour !</h6>
          </div>
          <a class="dropdown-item" @click="goToMyProfile()">
            <i class="ni ni-single-02"></i>
            <span>Mon profil</span>
          </a>
          <a class="dropdown-item" @click="gotToMyEvents()">
            <i class="ni ni-folder-17"></i>
            <span>Mes manifestations</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="logout">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>

        </template>
      </base-dropdown>
    </ul>
    <ul v-if="!isConnected()" class="navbar-nav align-items-center ml-auto ml-md-0 connect-container">
      <a class="nav-link pr-0" >
        <div >
          <base-button class="connect-button" size="sm" type="neutral" @click="login()">Se connecter</base-button>
        </div>
      </a>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import { EventBus } from '@/plugins/event-bus';
import SearchPrompt from '@/components/AppComponent/Prompt/SearchPrompt';

export default {
  components: {
    BaseNav,
    SearchPrompt,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    },
    showSearchBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeNotifications: false,
      searchField: '',
      state: "close",
      showMiniSearch: false,
    };
  },
  watch: {
    showMiniSearch(val) {
      if (val) {
        this.$refs.minisearchinputref.focus();
      }
    },
    '$route.query.search': {
      handler(newVal, oldVal) {
        if (newVal != this.searchField) {
          this.searchField = newVal ? newVal : '';
          this.performSearch();
        } else if (newVal != oldVal) {
          this.performSearch();
        }
      },
      immediate: true,
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    userInitials() {
      let initials = '';

      if (this.$store.getters['account/firstName']) {
        initials += this.$store.getters['account/firstName'].charAt(0);
      }

      if (this.$store.getters['account/lastName']) {
        initials += this.$store.getters['account/lastName'].charAt(0);
      }

      if (!initials) {
        initials = 'U';
      }

      return initials.toUpperCase();
    }
  },
  created () {
    // If the 'search' parameter is set it means that the user is loading the results of a search.
    // In this case, we fill the search field with the search text.
    this.searchField = this.$route.query.search;
  },
  mounted() {
    this.$sidebar.isMinimized = false;
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    search() {
      if (this.searchField) {
        this.$router.push({ query: { search: this.searchField }});
      } else {
        this.$router.push(this.$route.path);
      }
    },
    performSearch() {
      if (this.$refs.minisearchinputref) {
        this.$refs.minisearchinputref.blur();
      }
      this.showMiniSearch = false;
      EventBus.$emit('search', this.searchField);
    },
    clearField() {
      this.searchField = '';
      this.$router.push(this.$route.path);
    },
    goToMyProfile() {
      this.$router.push({ path: `/profile` });
    },
    gotToMyEvents() {
      this.$router.push({ path: `/myEvents` });
    },
    login() {
      window.location = `${process.env.VUE_APP_SP_URL}/login?appURL=${window.location.href}`;
    },
    logout() {
      this.$store.dispatch('account/logout');
      location.reload(); 
    },
    isConnected() {
      if (this.$store.getters['account/token'] != null) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
  .sidenav-toggler-container {
    margin-right: 12px;
  }

  .user-initials-container, .connect-container {
    margin-left: auto !important;
  }

  .connect-button {
    padding: 0.5rem;
  }
</style>