<template>
  <div v-if="showInstallPrompt" class="ath-container row align-items-center pt-4" :class="showInstallPrompt">
    <div class="col-lg-4">
      <div class="banner-bottom-center alert alert-mine d-flex flex-column">
        <div class="ath-banner">
          <div class="ath-banner-title">
            <p class="text-sm"></p>
          </div>
        </div>
        <div class="ath-banner pt-3">
          <div class="ath-banner-cell d-flex">
            <button class="btn btn-install btn-primary" :class="{'d-none': hideInstallButton}" @click="hideInstallButton = !hideInstallButton"></button>
            <button class="btn btn-cancel btn-link"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal, BaseAlert } from '@/components';

export default {
  name: 'InstallPrompt',
  data() {
    return {
      hideInstallButton: false,
    };
  },
  mounted() {
    this.promptUser()
  },
  computed: {
    showInstallPrompt() {
      return (!this.isCompatibleNavigator() || this.isInStandaloneMode()) ? false : true;
    }
  },
  methods: {
    isInStandaloneMode() {
      return ('standalone' in window.navigator) && (window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches;
    },
    promptUser() {
      /*let ath = addToHomescreen( {
        autostart: true,
        lifespan: 1000,
        debug: "iphone",
        logging: true,
        minSessions: 2,
        onShow: function () {
          console.log( "showing" );
        },
        onInit: function () {
          console.log( "initializing" );
        },
        onAdd: function () {
          console.log( "adding" );
        },
        onInstall: function () {
          console.log( "Installing" );
        },
        onCancel: function () {
          console.log( "Cancelling" );
        },
        mustShowCustomPrompt: true,
        customCriteria: true,
        displayPace: 0,
        customPrompt: {
          title: "Installer gratuitement l'application ! Elle ne prend pas de place sur votre téléphone.",
          cancelMsg: "Pas maintenant",
          installMsg: "Installer",
        }
      });*/
      let ath = addToHomescreen( {
        autostart: true,
        autoHide: 0,
        logging: false,
        customCriteria: true,
        displayPace: 1,
        mustShowCustomPrompt: true,
        customPrompt: {
          title: "Installez gratuitement l'application ! Elle ne prend pas de place sur votre téléphone.",
          cancelMsg: "Pas maintenant",
          installMsg: "Installer",
        }
      });

      if (!this.isCompatibleNavigator()) {
        ath._canPrompt = false;
      } else {
        ath._canPrompt = true;
        ath.trigger();
      }
    },
    isCompatibleNavigator() {
      // browser info and capability
      var _ua = window.navigator.userAgent;

      const isIDevice = ( /iphone|ipod|ipad/i ).test( _ua );
      const isSamsung = /Samsung/i.test( _ua );
      let isFireFox = /Firefox/i.test( _ua );
      let isOpera = /opr/i.test( _ua );
      const isEdge = /edg/i.test( _ua );

      // Opera & FireFox only Trigger on Android
      if ( isFireFox ) {
        isFireFox = /android/i.test( _ua );
      }

      if ( isOpera ) {
        isOpera = /android/i.test( _ua );
      }

      const isChromium = ( "onbeforeinstallprompt" in window && _ua.indexOf('Android') > -1);
      const isMobileSafari = isIDevice && _ua.indexOf( 'Safari' ) > -1 && _ua.indexOf( 'CriOS' ) < 0;
      return ( isChromium || isMobileSafari ||
        isSamsung || isFireFox || isOpera );
    },
  },
}
</script>

<style>
  .ath-guidance > img {
    max-width:100%;
    height:auto;
    padding-bottom: 1rem !important;
  }
</style>