import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/routes/routes";
import store from "@/stores/store";
import { isAllowed, verifyAndRenewToken } from "@/services/AuthService";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  // Try to connect or renew the user's token if it is disconnected.
  if (!store.getters["account/token"]) {
    try {
      await store.dispatch("account/login");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Shib session is not available", error);
      // Ignore, as we are not authentified
    }
  } else {
    // Check if the token is expired, if it is, it will try to login again to generate a new token
    await verifyAndRenewToken();
  }

  // Check if the user has the rights to access the page or if it access a connected-only page by being connected.
  if (isAllowed(to)) {
    next();
  } else {
    // Redirect it to home page if it is not allowed to access this page.
    next({ path: "/" });
  }
});

export default router;
