<template>
  <card class="text-center" body-classes="px-0 mx-md-4">
    <span class="news-date">{{ news.publicationDate | moment('DD.MM.YYYY - HH:mm:ss') }}</span>
    <div class="news-image" :style="`background: url('${getNewsImage(news.image)}')`"></div>
    <h2 class="news-title">{{ news.title }}</h2>
    <div v-bind:class="{ 'news-description-collapsed' : collapsed }" class="news-description text-justify" v-html="news.description"></div>
    <div v-bind:class="{ 'news-document-collapsed' : collapsed }" class="mt-4" v-if="news.documentName && news.documentPath">
      <a :href="news.isDocumentExternal ? news.documentPath : downloadNewsDocument(news.documentPath)" :target="news.isDocumentExternal ? '_blank' : ''">
        <i class="far fa-file-alt mr-1 document-icon"></i>
        {{ news.documentName }}
      </a>
    </div>
    <button class="btn news-expand" @click="expandCollapse">
      <i v-if="collapsed" class="fas fa-chevron-down"></i>
      <i v-else class="fas fa-chevron-up"></i>
    </button>
  </card>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      collapsed: true,
    };
  },
  props: {
    news: Object,
  },
  methods: {
    getNewsImage(imageName) {
      return `${process.env.VUE_APP_API_URL}/files/img/${imageName}`;
    },
    downloadNewsDocument(documentPath) {
      return `${process.env.VUE_APP_API_URL}/files/${documentPath}`;
    },
    expandCollapse() {
      this.collapsed = !this.collapsed;
    }
  },
};
</script>

<style>
  .news-description p {
    margin-bottom: 0;
  }
</style>

<style scoped>
  .news-date {
    color: #aaa;
    font-size: 0.8em;
    position: absolute;
    right: 1.5rem;
    top: 2px;
  }

  .news-image {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 12.5em;
    width: 100%;
  }

  .news-title {
    margin: 1.25em 0 1em 0;
  }

  .news-description {
    text-align-last: center;
  }

  .news-description-collapsed {
    max-height: 4.5em;
    overflow: hidden;
  }

  .news-document-collapsed {
    display: none;
  }

  .news-expand {
    font-size: 1.5em;
    padding: 1em 0 0 0;
  }

  .news-expand:hover {
    box-shadow: none;
    transform: none;
  }

  .news-expand:active, .news-expand:focus {
    box-shadow: none !important;
  }

  .card {
    box-shadow: 2px 4px 25px rgba(0, 0, 0, .1) !important;
  }
</style>