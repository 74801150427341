var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
                      name: 'Actualités',
                      icon: 'ni ni-email-83 text-mine',
                      path: '/news',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                      name: 'Manifestations',
                      icon: 'ni ni-bullet-list-67 text-mine',
                      path: '/events',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                      name: 'Vacances scolaires',
                      icon: 'fas fa-globe-europe text-mine',
                      path: '/holidays',
                    }}}),_c('sidebar-item',{staticClass:"mb-2",attrs:{"link":{
                      name: 'Calendrier',
                      icon: 'ni ni-calendar-grid-58 text-mine',
                      path: '/calendar',
                    }}}),_c('sidebar-item',{staticClass:"pt-2 border-top",attrs:{"link":{
                      name: 'Support',
                      icon: 'fa fa-life-ring text-mine',
                      path: '//support.ciip.ch/tickets-create?applications=AGENDA',
                      target: '_blank',
                      rel: 'noopener',
                    }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"showSearchBar":_vm.showSearchBar(),"type":_vm.$route.meta.navbarType}}),_c('div',{staticClass:"dashboard-content",on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }