<template>
  <div>
    <base-header class="pb-6" type="">
      <filter-prompt 
        :parentName="$options.name"
        :showFilter.sync="showFilter" 
        @close.stop="showFilter = false">
      </filter-prompt>
      <div class="row align-items-center py-4">
        <div class="d-flex">
          <h6 class="h2 d-inline-block mb-0 holidays-title">{{ title }}</h6>
          <base-button size="sm" type="neutral" class="ml-3" @click.stop="showFilter = !showFilter" v-if="!simpleSearch.active"><i class="fas fa-filter"></i></base-button>
        </div>
      </div>
      <!-- Event Cards -->
      <div class="row card-wrapper">
        <div class="col-lg-4 d-flex holiday" v-for="(item, index) in holidayList" :key="index">
          <holiday-card :item="item"></holiday-card>
        </div>
        <infinite-loading @infinite="infiniteHandler" ref="infiniteLoading" class="col-lg-4 d-flex flex-column justify-content-center">
          <div slot="no-more"></div>
          <div slot="no-results">Pas de vacances scolaires à venir</div>
        </infinite-loading>
      </div>
    </base-header>
  </div>
</template>

<script>
// Components
import HolidayCard from '@/components/AppComponent/Cards/HolidayCard';
import BaseHeader from '@/components/BaseHeader';
import InfiniteLoading from 'vue-infinite-loading';
import FilterPrompt from '@/components/AppComponent/Prompt/FilterPrompt';
import { setTimeout } from 'timers';
import { EventBus } from '@/plugins/event-bus';
import moment from 'moment';

export default {
  name: 'Holidays',
  data() {
    return {
      title: 'Vacances scolaires à venir',
      holidayList: [],
      simpleSearch: {
        text: '',
        active: false,
      },
      filterData: {
        dates: '',
        type: '',
      },
      paging: {
        currentPage: 0,
        totalPages: 0,
        perPage: 9,
      },
      showFilter: false,
    };
  },
  components: {
    BaseHeader,
    HolidayCard,
    InfiniteLoading,
    FilterPrompt,
  },
  created() {
    // If the 'search' parameter is set it means that the user want to show the results of a search.
    if (this.$route.query.search) {
      this.search(this.$route.query.search);
    }
  },
  mounted() {
    EventBus.$on('search', searchField => {
      this.search(searchField);
    });
  },
  watch: {
    // Watch after the parameters of the query in order to start a filter process if the right parameters are set
    '$route.query': {
      handler(newVal, oldVal) {
        if ((newVal.fromMonth && newVal.toMonth) || newVal.type) {
          this.filter({
            dates: newVal.fromMonth && newVal.toMonth ? [
              `01-${newVal.fromMonth}`,
              `${moment(newVal.toMonth, "MM-YYYY").daysInMonth()}-${newVal.toMonth}`, // Get last date of the month
            ] : '',
            type: newVal.type,
          });
        } else {
          this.filter({
            dates: '',
            type: '',
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    resetHoliday() {
      this.holidayList = [];
      this.paging.currentPage = 0;
      this.paging.totalPages = 1;
      this.filterData = {
        dates: '',
        type: '',
      };
    },
    search(text) {
      this.resetHoliday();
      this.simpleSearch.text = text;
      if (text != '') {
        this.simpleSearch.active = true;
      } else {
        if (this.$refs.infiniteLoading != undefined) {
          this.simpleSearch.active = false;
          this.$refs.infiniteLoading.stateChanger.reset();
        }
      }
    },
    filter(data) {
      this.resetHoliday();
      this.filterData = data;

      if (this.$refs.infiniteLoading != undefined) {
        this.$refs.infiniteLoading.stateChanger.reset();
      }
    },
    infiniteHandler($state) {
      const date = new Date();
      const dateStr = date.toUTCString();
      let url;
      let params;

      // When the user searched holidays with the search field.
      if (this.simpleSearch.active === true) {
        this.title = `Vacances scolaires`;

        url = '/holidayEntities/search/fulltext';
        params = {
          acronym: this.simpleSearch.text,
          page: this.paging.currentPage,
          size: this.paging.perPage,
          title: this.simpleSearch.text,
          description: this.simpleSearch.text,
          canton: this.simpleSearch.text,
        };
      // When the user filtered holidays by date.
      } else if (this.filterData.dates) {
        this.title = `Vacances scolaires du ${this.filterData.dates[0]} au ${this.filterData.dates[1]}`;

        url = '/holidayEntities/search/findBetweenDates';
        params = {
          start: new Date(`${this.filterData.dates[0].substring(6, 10)}-${this.filterData.dates[0].substring(3, 5)}-${this.filterData.dates[0].substring(0, 2)}`).toUTCString(),
          end: new Date(`${this.filterData.dates[1].substring(6, 10)}-${this.filterData.dates[1].substring(3, 5)}-${this.filterData.dates[1].substring(0, 2)}`).toUTCString(),
          page: this.paging.currentPage,
          size: this.paging.perPage,
        };
      // When the user chose to display past holidays only.
      } else if (this.filterData.type === 'pastEvents') {
        this.title = `Vacances scolaires passées`;

        url = '/holidayEntities/search/holidaysToTodayAsc';
        params = {
          page: this.paging.currentPage,
          size: this.paging.perPage,
          dateNow: dateStr,
        };
      // When the user chose to display all the holidays.
      } else if (this.filterData.type === 'allEvents') {
        this.title = `Toutes les vacances scolaires`;

        url = '/holidayEntities/search/findAllOrderByStartTimeDesc';
        params = {
          page: this.paging.currentPage,
          size: this.paging.perPage,
          dateNow: dateStr,
        };
      // When the user chose to display futur holidays only (by default).
      } else {
        this.title = `Vacances scolaires à venir`;
        
        url = '/holidayEntities/search/holidaysFromTodayAsc';
        params = {
          page: this.paging.currentPage,
          size: this.paging.perPage,
          dateNow: dateStr,
        };
      }

      return this.$http
        .get(process.env.VUE_APP_API_URL + url, { params }).then((response) => {
          if (response.status === 200) {
            /* eslint no-underscore-dangle: ["error", { "allow": ["_embedded"] }] */
            this.paging.totalPages = response.data.page.totalPages;
            if (this.paging.currentPage < this.paging.totalPages) {
              this.paging.currentPage += 1;
              this.holidayList = [...this.holidayList, ...response.data._embedded.holidayEntities];
              $state.loaded();
            } else {
              $state.complete();
            }
          }
        });
    },
    beforeDestroy() {
      EventBus.$off('search', this.searchField);
    }
  },
};
</script>

<style scoped>
  @media (max-width: 767px) {
    .holidays-title {
      margin-left: 15px;
    }
  }
</style>