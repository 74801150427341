import DashboardLayout from '@/views/ApplicationViews/Layout/DashboardLayout';

import News from '@/views/ApplicationViews/News';

import Events from '@/views/ApplicationViews/Events';
import MyEvents from '@/views/ApplicationViews/MyEvents';
import EventDetails from '@/views/ApplicationViews/Details/EventDetails';
import Feedback from '@/views/ApplicationViews/Feedback';

import Holidays from '@/views/ApplicationViews/Holidays';
import HolidayDetails from '@/views/ApplicationViews/Details/HolidayDetails';

import Profile from '@/views/ApplicationViews/Profile/UserProfile';

import Calendar from '@/views/ApplicationViews/Calendar';

import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

const news = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: 'news',
      name: 'News',
      component: News,
    },
  ],
};

const events = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: 'events',
      name: 'Events',
      component: Events,
    },
    {
      path: 'myEvents',
      name: 'myEvents',
      component: MyEvents,
    },
    {
      path: 'event/:id',
      name: 'EventDetails',
      component: EventDetails,
    },
    {
      path: 'event/:id/feedback',
      name: 'Feedback',
      component: Feedback,
      props: {
        default: true,
        eventTitle: String,
        imgUrl: String,
      },
    }
  ],
};

const holidays = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: 'holidays',
      name: 'Holidays',
      component: Holidays,
    },
    {
      path: 'holiday/:id',
      name: 'HolidayDetails',
      component: HolidayDetails,
    },
  ],
};

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/events',
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar,
      },
    ]
  },
  news,
  events,
  holidays,
  { path: '*', component: NotFound }
];
  
export default routes;